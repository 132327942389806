<template>
	<div class="bigBox">
		<div class="">
			<el-page-header @back="goBack" content="新增设备详情" class="heardGoBack">
			</el-page-header>
			<el-divider></el-divider>
			<div class="newsText row-me">
				<div class="lefts">
					<!-- 基础信息 -->
					<div class="">
						<p>基础信息</p>
						<el-form ref="detalisArr" :model="detalisArr" label-position="right" label-width="100px">
							<div class="fontBox">
								<el-form-item label="IEME编码" required="true">
									<span>{{detalisArr.ieme_encoded}}</span>
								</el-form-item>
								<el-form-item label="设备编码" required="true">
									<span>{{detalisArr.device_encoded}}</span>
								</el-form-item>
								<el-form-item label="设备名称" required="true">
									<span>{{detalisArr.name}}</span>
								</el-form-item>
								<el-form-item label="设备应用">
									<span>{{detalisArr.device_cate}}</span>
								</el-form-item>
								<el-form-item label="设备标签">
									<span>{{detalisArr.label}}</span>
								</el-form-item>
								<el-form-item label="所在地区">
									<span>{{detalisArr.areainfo}}</span>
								</el-form-item>
								<el-form-item label="转速" required="true">
									<span>{{detalisArr.speed}}</span>
								</el-form-item>
								<el-form-item label="荷载状况">
									<!-- //载荷状态，1：轻，2：中，3：重 -->
									<span v-if="detalisArr.load_state == 1">轻</span>
									<span v-if="detalisArr.load_state == 2">中</span>
									<span v-if="detalisArr.load_state == 3">重</span>
								</el-form-item>

								<el-form-item label="工作温度">
									<text>
										{{temperature}}
									</text>
								</el-form-item>
							</div>
						</el-form>
					</div>
					<!-- 传感设置 -->
					<div class="">
						<p>传感设置</p>
						<el-form ref="detalisArr" :model="detalisArr" label-position="right" label-width="120px">
							<div class="fontBox">
								<el-form-item label="电流传感器" required="true">
									<el-switch v-model="detalisArr.current_sensing">
									</el-switch>
								</el-form-item>
								<el-form-item label="循环阈值" required="true">
									<div class="column-me row-center">
										<el-radio v-model="detalisArr.threshold" label="1" class="margin-top18">循环次数（次）
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												style="width: 100px;" v-model="detalisArr.threshold_loop"
												@blur="threshold_loop()"></el-input>
										</el-radio>
										<el-radio v-model="detalisArr.threshold" label="2" class="margin-top18">喷油时间（秒）
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												style="width: 100px;" v-model="detalisArr.threshold_time"
												@blur="threshold_time()"></el-input>
										</el-radio>
										<div class="row-me margin-top18">
											<span class="font-size18">间隔时间(小时)</span>
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												style="width: 100px;margin-left: 10px;"
												v-model="detalisArr.interval_time" @blur="interval_time()">
											</el-input>
										</div>
									</div>
								</el-form-item>
							</div>
						</el-form>
					</div>
					<!-- 传感器警告设置 -->
					<div class="">
						<p> 传感器警告设置</p>
						<el-form ref="detalisArr" :model="detalisArr" label-position="right" label-width="120px">
							<div class="fontBox">
								<el-form-item label="循环警告阈值">
									<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
										v-model="detalisArr.loop_warn_threshold" class="width400"
										@blur="loop_warn_threshold()">
										<template #prepend>循环时间（s）</template>
									</el-input>
								</el-form-item>
								<el-form-item label="压力警告阈值">
									<div class="column-me stressBox">
										<div class="row-me row-center">
											<el-input placeholder="请输入" v-model="detalisArr.low_voltage_value"
												onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
												class="flex1" @blur="low_voltage_value()">
												<template #prepend>低压值(MPa)</template>
											</el-input>
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												v-model="detalisArr.low_voltage_time" class="flex1"
												@blur="low_voltage_time()">
												<template #prepend>持续时间(分钟)</template>
											</el-input>
										</div>
										<div class="row-me row-center">
											<el-input placeholder="请输入" v-model="detalisArr.high_voltage_value"
												onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
												class="flex1" @blur="high_voltage_value()">
												<template #prepend>高压值(MPa)</template>
											</el-input>
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												v-model="detalisArr.high_voltage_time" class="flex1"
												@blur="high_voltage_time()">
												<template #prepend>持续时间(分钟)</template>
											</el-input>
										</div>
									</div>

								</el-form-item>
								<div class="machineBox">
									<el-form-item label="手动启停控制">
										<el-switch v-model="machine" @change="machineOnch">
										</el-switch>
									</el-form-item>
								</div>
							</div>
						</el-form>
					</div>

				</div>
				<div class="rights">
					<el-form ref="detalisArr" :model="detalisArr" label-position="right" label-width="120px">
						<el-form-item label="现场照片">
							<div v-if="detalisArr.images == ''">暂无</div>
							<img :src="detalisArr.images" v-else style="width: 187px;height: 140px;" class="sitePhotos">
						</el-form-item>
						<el-form-item label="轴承型号">
							<div class="column-me">
								<div class="">
									<span>开启二组型号</span>
									<el-switch v-model="value1" />
								</div>
								<div class="row-me">

									<div class="">
										<div class="column-me margin-top20">
											<img src="../../assets/img/Snipaste.png" class="typeImg" />
											<el-table :data="tab0" border header-cell-class-name="table-header-style"
												cell-class-name="table-cell-style" class="tabBox">
												<!-- <el-table :data="tab0" style="width: 444px;height: 200px;margin-top: 20px;"> -->
												<el-table-column prop="seat_name" label="位置" width="60" />
												<el-table-column prop="modelA" label="轴承型号1"
													:show-overflow-tooltip="true" :render-header="renderHeader" />
												<el-table-column prop="modelB" label="轴承型号2"
													:show-overflow-tooltip="true" :render-header="renderHeader" />
												<el-table-column prop="value" label="喷油量(ml)">
													<template #default="scope">
														<el-input v-model="scope.row.value"
															onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
															placeholder="请输入"></el-input>
													</template>
												</el-table-column>
											</el-table>
											<!-- 柱状图 -->
											<div class="earchs">
												<span class="titTxt">本月油脂消耗</span>
												<div ref="chart" class="chartBox"></div>

											</div>
										</div>
									</div>
									<div class="margin-left20" v-show="value1==true">
										<div class="column-me margin-top20">
											<img src="../../assets/img/Snipaste2.png" class="typeImg" />
											<el-table :data="tab1" border header-cell-class-name="table-header-style"
												cell-class-name="table-cell-style" class="tabBox">
												<!-- <el-table :data="tab1" style="width: 444px;height: 200px;margin-top: 20px;"> -->
												<el-table-column prop="seat_name" width="60" label="位置" />
												<el-table-column prop="modelA" label="轴承型号1"
													:show-overflow-tooltip="true" :render-header="renderHeader" />
												<el-table-column prop="modelB" label="轴承型号2"
													:show-overflow-tooltip="true" :render-header="renderHeader" />
												<el-table-column prop="value" label="喷油量(ml)">
													<template #default="scope">
														<el-input v-model="scope.row.value" placeholder="请输入"
															onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"></el-input>
													</template>
												</el-table-column>
											</el-table>
											<!-- 柱状图 -->
											<div class="earchs">
												<span class="titTxt">本月油脂消耗</span>
												<div ref="chart1" class="chartBox"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<el-divider></el-divider>
			<div class="btnsTwo row-me row-center flex-end">
				<el-button class="btns" @click="preserveNoBtn">取消</el-button>
				<el-button class="btns" type="primary" @click="preserveBtn">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		deviceDetails
	} from "@/api/deviceManagement/deviceManagement.js"
	export default {
		data() {
			return {
				valueSwich: false,
				radio: 1,
				input1: "",
				size: "small",
				form: {},
				detalisArr: {},
				value1: false,
				tableData: [],
				MS0: ["M1", "M2", "S1", "S2"],
				info0: [0, 0, 0, 0],
				MS1: ["M1", "M2", "S1", "S2"],
				info1: [0, 0, 0, 0],
				tab0: [{
					seat_name: 'M1',
					group_num: 1,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}, {
					seat_name: 'M2',
					group_num: 1,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}, {
					seat_name: 'S1',
					group_num: 1,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}, {
					seat_name: 'S2',
					group_num: 1,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}],
				tab1: [{
					seat_name: 'M3',
					group_num: 2,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}, {
					seat_name: 'M4',
					group_num: 2,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}, {
					seat_name: 'S3',
					group_num: 2,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}, {
					seat_name: 'S4',
					group_num: 2,
					modelA: '',
					modelB: '',
					value: '', //喷油量
				}],
				temperature: "", //解析温度
				machine: false, //手动启
			}
		},
		mounted() {
			this.getDetalis()
		},
		methods: {
			// 设备参数单独修改接口
			deviceConfigSaveWay(field_name, value) {
				this.$post("/admin/deviceConfigSave", {
					id: this.$route.params.id,
					field_name: field_name,
					value: value
				}).then((res) => {
					if (res) {
						// this.comList = res.data;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 失去焦点-间隔时间
			interval_time() {
				if (this.detalisArr.interval_time1 != this.detalisArr.interval_time) {
					this.deviceConfigSaveWay('interval_time', this.detalisArr.interval_time);
					this.detalisArr.interval_time1 = this.detalisArr.interval_time;
				}
			},
			// 失去焦点-喷油时间
			threshold_time() {
				if (this.detalisArr.threshold_time1 != this.detalisArr.threshold_time) {
					this.deviceConfigSaveWay('threshold_time', this.detalisArr.threshold_time);
					this.detalisArr.threshold_time1 = this.detalisArr.threshold_time;
				}
			},
			// 失去焦点-循环次数
			threshold_loop() {
				if (this.detalisArr.threshold_loop1 != this.detalisArr.threshold_loop) {
					this.deviceConfigSaveWay('threshold_loop', this.detalisArr.threshold_loop);
					this.detalisArr.threshold_loop1 = this.detalisArr.threshold_loop;
				}
			},
			// 循环时间-循环警告阈值
			loop_warn_threshold() {
				if (this.detalisArr.loop_warn_threshold1 != this.detalisArr.loop_warn_threshold) {
					this.deviceConfigSaveWay('loop_warn_threshold', this.detalisArr.loop_warn_threshold);
					this.detalisArr.loop_warn_threshold1 = this.detalisArr.loop_warn_threshold;
				}
			},
			// 低压值-压力警告阈值
			low_voltage_value() {
				if (this.detalisArr.low_voltage_value1 != this.detalisArr.low_voltage_value) {
					this.deviceConfigSaveWay('low_voltage_value', this.detalisArr.low_voltage_value);
					this.detalisArr.low_voltage_value1 = this.detalisArr.low_voltage_value;
				}
			},
			// 低压值-持续时间-压力警告阈值
			low_voltage_time() {
				if (this.detalisArr.low_voltage_time1 != this.detalisArr.low_voltage_time) {
					this.deviceConfigSaveWay('low_voltage_time', this.detalisArr.low_voltage_time);
					this.detalisArr.low_voltage_time1 = this.detalisArr.low_voltage_time;
				}
			},
			// 高压值-压力警告阈值
			high_voltage_value() {
				if (this.detalisArr.high_voltage_value1 != this.detalisArr.high_voltage_value) {
					this.deviceConfigSaveWay('high_voltage_value', this.detalisArr.high_voltage_value);
					this.detalisArr.high_voltage_value1 = this.detalisArr.high_voltage_value;
				}
			},
			// 高压值-持续时间-压力警告阈值
			high_voltage_time() {
				if (this.detalisArr.high_voltage_time1 != this.detalisArr.high_voltage_time) {
					this.deviceConfigSaveWay('high_voltage_time', this.detalisArr.high_voltage_time);
					this.detalisArr.high_voltage_time1 = this.detalisArr.high_voltage_time;
				}
			},
			goBack() {
				this.$router.go(-1);
			},
			// 详情数据
			async getDetalis() {


				let id = this.$route.params.id
				const res = await deviceDetails({
					id: id
				})
				if (res.data.machine == 'OFF') {
					this.machine = false;
				} else {
					this.machine = true;
				}
				// 详情数据
				this.detalisArr = res.data;

				this.detalisArr.interval_time1 = this.detalisArr.interval_time;
				this.detalisArr.threshold_time1 = this.detalisArr.threshold_time;
				this.detalisArr.threshold_loop1 = this.detalisArr.threshold_loop;
				this.detalisArr.loop_warn_threshold1 = this.detalisArr.loop_warn_threshold;
				this.detalisArr.low_voltage_value1 = this.detalisArr.low_voltage_value;
				this.detalisArr.low_voltage_time1 = this.detalisArr.low_voltage_time;
				this.detalisArr.high_voltage_value1 = this.detalisArr.high_voltage_value;
				this.detalisArr.high_voltage_time1 = this.detalisArr.high_voltage_time;



				if (this.detalisArr.industry_temp == 1) {
					this.temperature = '47°C ≤ 温度 < 63°C';
				} else if (this.detalisArr.industry_temp == 2) {
					this.temperature = '63°C ≤ 温度 < 78°C';
				} else if (this.detalisArr.industry_temp == 3) {
					this.temperature = '78°C ≤ 温度 < 93°C';
				} else if (this.detalisArr.industry_temp == 4) {
					this.temperature = '93°C ≤ 温度 < 107°C';
				}

				if (this.detalisArr.bearing_level == 1) {
					this.value1 = false;
				} else if (this.detalisArr.bearing_level == 2) {
					this.value1 = true;
				}
				// 电流传感，0：关，1：开
				if (this.detalisArr.current_sensing == 1) {
					this.detalisArr.current_sensing = true
				} else if (this.detalisArr.current_sensing == 0) {
					this.detalisArr.current_sensing = false
				}
				if (res.data.threshold != null) {
					this.detalisArr.threshold = res.data.threshold.toString()
				}
				// earchs图表
				if (res.data.deviceGrease[0].coordinate.length > 0) {
					this.MS0 = res.data.deviceGrease[0].coordinate; //底
					this.info0 = res.data.deviceGrease[0].dataInfo; //左
				} else {
					this.MS0 = ["M1", "M2", "S1", "S2"];
					this.info0 = [0, 0, 0, 0];
				}
				if (res.data.deviceGrease[1].coordinate.length > 0) {
					this.MS1 = res.data.deviceGrease[1].coordinate; //底
					this.info1 = res.data.deviceGrease[1].dataInfo; //左
				} else {
					this.MS1 = ["M3", "M4", "S3", "S4"];
					this.info1 = [0, 0, 0, 0];
				}
				const chart = this.$refs.chart
				const chart1 = this.$refs.chart1
				if (chart) {
					const myChart = this.$echarts.init(chart)
					const option = {
						xAxis: {
							type: 'category',
							data: JSON.parse(JSON.stringify(this.MS0))
						},
						yAxis: {
							type: 'value'
						},
						series: [{
							type: 'bar',
							data: JSON.parse(JSON.stringify(this.info0))
						}]
					};
					myChart.setOption(option)
					window.addEventListener("resize", function() {
						myChart.resize()
					})
				}
				if (chart1) {
					const myChart1 = this.$echarts.init(chart1)
					const option1 = {
						xAxis: {
							type: 'category',
							data: JSON.parse(JSON.stringify(this.MS1))
						},
						yAxis: {
							type: 'value'
						},
						series: [{
							data: JSON.parse(JSON.stringify(this.info1)),
							type: 'bar'
						}]
					};
					myChart1.setOption(option1)
					window.addEventListener("resize", function() {
						myChart1.resize()
					})
				}



				// 表格
				if (res.data.bearModelData[0].length > 0) {
					this.tab0 = res.data.bearModelData[0]
				}
				if (res.data.bearModelData[1].length > 0) {
					this.tab1 = res.data.bearModelData[1]
				}

			},
			machineOnch() {
				var machine;
				if (this.machine == false) {
					machine = 'OFF'
				} else {
					machine = 'ON'
				}
				var data = {
					id: this.detalisArr.id,
					machine: machine,
				}
				this.$post("/admin/deviceMachineOperate", data).then((res) => {
					if (res) {
						this.$message.success('修改成功');
						// this.$router.go(-1);
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 保存按钮
			preserveBtn() {
				this.detalisArr.bearModelData = [];
				// this.getDetalis()
				// 66666666666666
				// 电流传感，0：关，1：开
				if (this.detalisArr.current_sensing == true) {
					this.detalisArr.current_sensing = 1
				} else if (this.detalisArr.current_sensing == false) {
					this.detalisArr.current_sensing = 0
				}

				// 循环阈值：1：循环次数，2：喷油时间
				// this.detalisArr.threshold = Number(this.detalisArr.threshold)
				// if (this.detalisArr.threshold == 1) {
				// 	delete this.detalisArr.threshold_time
				// } else if (this.detalisArr.threshold == 2) {
				// 	delete this.detalisArr.threshold_loop
				// }

				if (this.value1 == true) {
					// 轴承型号， 1： 第一组， 2： 第二组
					this.detalisArr.bearing_level = 2;
					this.detalisArr.bearModelData = [];
					this.detalisArr.bearModelData.push(this.tab0);
					this.detalisArr.bearModelData.push(this.tab1);
				} else {
					this.detalisArr.bearModelData.push(this.tab0);
					this.detalisArr.bearing_level = 1;
				}

				console.log(this.detalisArr, '55555555');
				// return false;
				this.$post("/admin/deviceInfoEdit", this.detalisArr).then((res) => {
					if (res) {
						this.$message.success('保存成功');
						// this.$router.go(-1);
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})

			},
			// 取消按钮
			preserveNoBtn() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.stressBox {
		/deep/.el-input-group__prepend {
			padding: 0 5px !important;
		}
	}

	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
		height: calc(100vh - 180px);
		overflow-y: scroll;

		.typeImg {
			width: 444px;
			height: 150px;
			border: 1px solid #D6D9E2;
			margin-bottom: 20px;
		}

		.tabBox {
			width: 444px;
		}

		.btns {
			width: 100px;
			height: 40px;
			font-size: 18px;
		}

		.newsText {
			width: 100%;
			height: auto;

			p {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				line-height: 21px;
				color: #252630;
			}

			.fontBox {
				font-size: 16px !important;
				margin-left: 49px;
				margin-top: 39px;
			}

			.lefts {
				width: 32%;
				// width: 480px;
				height: 100%;
			}

			.rights {
				width: 60%;
				height: 100%;
			}

			.machineBox {

				// margin-left: 60px;
				// margin-left: 49px;
				/deep/.el-form-item__label {
					font-size: 18px !important;
				}
			}

			.earchs {
				width: 5.9rem;
				// width: 444px;
				height: 3rem;
				// height: 280px;
				margin-top: 20px;
				text-align: center;
				background-color: #fff;
				position: relative;
				border: 1px solid #D6D9E2;
				// background-color: #D6D9E2;

				.titTxt {
					width: 100%;
					// background-color: indianred;
					position: absolute;
					top: 10px;
					left: 0;
					font-size: 16px;
					font-weight: bold;
				}

				.chartBox {
					// width: 400px;
					// height: 260px;
					width: 5.9rem;
					// width: 444px;
					height: 3.5rem;
					margin-top: -10px;

					div {
						// width: 444px;
						// width: 100%;
					}

					canvas {
						// width: 444px;
						// width: 100% !important;
					}
				}
			}
		}
	}

	::v-deep .el-form-item__label {
		font-size: 16px !important;
	}
</style>